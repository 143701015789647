<template>
    <v-dialog
        v-model='dialog'
        max-width='500'
    >
        <v-card>
            <v-form v-model='formValid' ref='formulario'>
                <v-toolbar color='primary' dark dense>
                    <v-toolbar-title>
                        Detalles del archivo
                    </v-toolbar-title>

                    <v-spacer/>

                    <v-btn
                        icon
                        @click='dialog=false'
                    >
                        <v-icon >
                            mdi-close-box
                        </v-icon>
                    </v-btn>
                </v-toolbar>


                <v-card-text>
                    <!-- Contenido del dialog -->
                    <v-text-field
                        label="Liga para descarga"
                        v-model="archivoLiga"
                    />

                    <v-text-field
                        v-if="datos.tipoDocumento != 99"
                        label="Tipo de archivo"
                        v-model="datos.tipoDocumentoNombre"
                    />

                    <v-text-field
                        label="Tipo de archivo"
                        v-if="datos.tipoDocumento == 99"
                        v-model="datos.otroDocumento"
                    />

                    <v-text-field
                        label="Fecha de creación"
                        v-model="datos.dateCreated"
                    />

                    <v-text-field
                        label="Creado por"
                        v-model="datos.crea"
                    />

                    <v-textarea
                        label="Observaciones"
                        v-model="datos.observaciones"
                    />
                        <div class="text-center">
                        <v-icon size="120px" @click="baja">
                            mdi-file-import
                        </v-icon>
                        </div>
                        <div class="text-center">Descargar archivo</div>
                </v-card-text>

            </v-form>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props:{
        dialogDetalles:Boolean,
        datos:Object,
    },
    data:()=>({
        formValid:false,
        loadingBtn:false,
    }),
    methods:{
        async baja(){
            let url = this.$baseUrl+'admin/getArchivo/?id='+this.datos.archivo
            window.open(url,'winname','directories=no,titlebar=no,toolbar=no,location=0,status=no,menubar=no,scrollbars=no,resizable=no,width=800,height=600')
        },
    },
    computed:{
        archivoLiga(){
            return(this.$baseUrl+'admin/getArchivo?id='+this.datos.archivo)
        },
        dialog:{
            get(){
                return this.dialogDetalles
            },
            set(val){
                this.$emit('update:dialogDetalles',val)
            },
        },
    },
    watch:{
    },
}
</script>

<style>
</style>